import { Link } from '@maggie/cordova/link';
import { ScreenLock } from '@maggie/cordova/screen-lock';
import { Taptic } from '@maggie/cordova/taptic';
import { PeerAuthoringBridge } from '@maggie/core/lessons/peer_authoring/peer_authoring';
import type { SocialLearningCommentLoadedEvent } from '@maggie/core/lessons/social_learning/types';
import { ThomasActions } from '@maggie/store/thomas/actions';

import { HostedWebviewUtils } from '../../hosted_webview_utils';
import { eventManager } from '../event-manager';
import type { ContextForThomas } from '../thomas-player/thomas-player-interface';
import { IFrameBridge } from './iframe-bridge';
import type {
  GoToPagePayload,
  NarrationCompletedPayload,
  NarrationStartedPayload,
  NextSlidePayload,
  SlideCompletedPayload
} from './types';
import { dataToGlobal, slidedeckPrefix } from './utils';

export const codeToString = (event: string, data?: any) => {
  if (data !== null && data !== undefined) {
    return `Backbone.Events.trigger('${event}', ${JSON.stringify(data)});`;
  } else {
    return `Backbone.Events.trigger('${event}');`;
  }
};

/**
 * Thomas Bridge is the interface between the learner's app and the Thomas Engine which runs the lesson content.
 * It is responsible for chosing the appropriate bridge implementation, as well as communicating to the engine
 * running the content by executing scripts inside the target window.
 *
 * @export
 * @class ThomasBridge
 */
export class ThomasBridge {
  private peerAuthoringBridge: PeerAuthoringBridge;
  private iFrameBridge: IFrameBridge;
  private isOpen: boolean;

  constructor(iframeParent: string) {
    this.iFrameBridge = new IFrameBridge(iframeParent);
    this.peerAuthoringBridge = new PeerAuthoringBridge();
    this.isOpen = false;
  }

  private onNarrationStarted = (data: NarrationStartedPayload) => {
    if (data.can_not_be_skipped) {
      window.__store.dispatch(ThomasActions.disableNavigation());
    }
  };

  private onNarrationCompleted = (data: NarrationCompletedPayload) => {
    if (data.can_not_be_skipped) {
      window.__store.dispatch(ThomasActions.enableNavigation());
    }
  };

  /**
   * For every slide there are two ":slide-completed" events
   *
   * Not sure if this is a bug or not. But keeping a comment here for reference in the future.
   * The first one comes from `thomas/slide.coffee` => it will contain the correct payload
   * The second one comes from `thomas/game.coffee` => it will contain empty payload
   */
  private onSlideCompleted = (data: SlideCompletedPayload) => {
    if (!!data && !!data.isInteractive && !!data.hasAnswerFeedback) {
      Taptic.notification(data.isCorrect ? 'success' : 'error');
    }

    HostedWebviewUtils.triggerEvent('slideCompleted');
  };

  private onSlideRendered = () => {
    HostedWebviewUtils.triggerEvent('slideRendered');
  };

  private onNextSlide = (data: NextSlidePayload) => {
    if (data?.slideIndex !== undefined) {
      window.__store.dispatch(ThomasActions.setLastCompletedSlideIndex(data.slideIndex));
    }
  };

  private onGoToPage = (data: GoToPagePayload) => {
    window.__store.dispatch(ThomasActions.setSlideIndex(data.value));
  };

  private onOpenMenu = () => {
    window.__store.dispatch(ThomasActions.setIsOpenMenu(true));
    document.getElementById('slide-nav-lesson-title')?.focus();
  };

  private onRotationLock = () => {
    const { getMain, isSplitViewLayout } = window.__router;
    const { routeName } = getMain();
    // only re-lock if the device is mobile (see AppLaunch.tsx) primarily for video slides
    if (!isSplitViewLayout(routeName) && window.__isMobile) {
      ScreenLock.lockToPortrait();
    }
  };

  private onRotationUnlock = () => {
    const { getMain, isSplitViewLayout } = window.__router;
    const { routeName } = getMain();
    // only unlock if the device is mobile (see AppLaunch.tsx) primarily for video slides
    if (!isSplitViewLayout(routeName) && window.__isMobile) {
      ScreenLock.unlock();
    }
  };

  /**
   * Listen to external url access
   */
  private onUrlOpen = (e: { url: string }) => {
    Link.openExternalUrl(e.url);
  };

  private onNoMoreComments = () => {
    const code = codeToString('subscribe:event:nomore-comments');
    this.iFrameBridge.executeScript(code);
  };

  private onPauseTracking = () => {
    const code = codeToString('subscribe:event:pause-tracking', {});
    this.iFrameBridge.executeScript(code);
  };

  private onResumeTracking = () => {
    const code = codeToString('subscribe:event:resume-tracking', {});
    this.iFrameBridge.executeScript(code);
  };

  private onErrorSL = (message: string) => {
    const code = codeToString('subscribe:event:error-sl', message);
    this.iFrameBridge.executeScript(code);
  };

  private onCommentLoaded = ({ comments, total, hasMore }: SocialLearningCommentLoadedEvent) => {
    const data = { comments, total, hasMore };
    const code = codeToString('subscribe:event:comment-loaded', data);
    this.iFrameBridge.executeScript(code);
  };

  private onNewStar = (data: any) => {
    const code = codeToString('subscribe:event:new-star', data);
    this.iFrameBridge.executeScript(code);
  };

  public registerStarListener = () => {
    eventManager.listenTo('new-star', this.onNewStar);
  };

  public goToPage(pageIndex: number) {
    const code = codeToString('subscribe:event:goToPage', pageIndex);
    this.iFrameBridge.executeScript(code);
  }

  /**
   * This adjusts the header of thomas to be responsive
   *
   * `desktop` - will hide page count, title, and nav button
   * `mobile` - will show page count, title, and nav button
   */
  public toggleHeader(mode: 'desktop' | 'mobile') {
    const togglePageCount = codeToString('subscribe:event:toggle-page-count', mode === 'mobile');
    this.iFrameBridge.executeScript(togglePageCount);

    const toggleTitle = codeToString('subscribe:event:toggle-title', mode === 'mobile');
    this.iFrameBridge.executeScript(toggleTitle);

    const toggleNavBtn = codeToString('subscribe:event:toggle-nav-btn', mode === 'mobile');
    this.iFrameBridge.executeScript(toggleNavBtn);

    const toggleOpenMenuExternally = codeToString(
      'subscribe:event:toggle-open-menu-externally',
      mode === 'mobile'
    );
    this.iFrameBridge.executeScript(toggleOpenMenuExternally);
  }

  public closeThomas = async (id: string) => {
    this.isOpen = false;

    this.peerAuthoringBridge.deactivate();

    this.iFrameBridge.destroyIframe();

    const prefix = slidedeckPrefix(id);
    eventManager.stopListening(`${prefix}:thomas-ready`);
    eventManager.stopListening(`${prefix}:narration-started`);
    eventManager.stopListening(`${prefix}:narration-completed`);
    eventManager.stopListening(`${prefix}:go-to-page`);
    eventManager.stopListening(`${prefix}:slide-completed`);
    eventManager.stopListening(`${prefix}:next-slide`);
    eventManager.stopListening(`${prefix}:slide-rendered`);
    eventManager.stopListening(`${prefix}:open-menu`);
    eventManager.stopListening(`${prefix}:rotation-lock`);
    eventManager.stopListening(`${prefix}:rotation-unlock`);
    eventManager.stopListening(`${prefix}:url-open`);
    eventManager.stopListening('comment-loaded');
    eventManager.stopListening('error-sl');
    eventManager.stopListening('resume-tracking');
    eventManager.stopListening('pause-tracking');
    eventManager.stopListening('nomore-comments');
    eventManager.stopListening('new-star');
  };

  public openThomas = async (context: ContextForThomas, url: string) => {
    /**
     * This promise opens thomas and waits for thomas to broadcast `thomas-ready` event.
     */
    const openPromise = new Promise<void>(async (resolve, reject) => {
      try {
        this.isOpen = true;

        // create iframe & inject context
        await this.iFrameBridge.createIframe(url, context.slidedeck.platform);
        const script = dataToGlobal(context);
        this.iFrameBridge.executeScript(script);

        const prefix = slidedeckPrefix(context.slidedeck.id);
        eventManager.listenTo(`${prefix}:narration-started`, this.onNarrationStarted);
        eventManager.listenTo(`${prefix}:narration-completed`, this.onNarrationCompleted);
        eventManager.listenTo(`${prefix}:go-to-page`, this.onGoToPage);
        eventManager.listenTo(`${prefix}:slide-completed`, this.onSlideCompleted);
        eventManager.listenTo(`${prefix}:next-slide`, this.onNextSlide);
        eventManager.listenTo(`${prefix}:slide-rendered`, this.onSlideRendered);
        eventManager.listenTo(`${prefix}:open-menu`, this.onOpenMenu);
        eventManager.listenTo(`${prefix}:rotation-lock`, this.onRotationLock);
        eventManager.listenTo(`${prefix}:rotation-unlock`, this.onRotationUnlock);
        eventManager.listenTo(`${prefix}:url-open`, this.onUrlOpen);
        eventManager.listenTo('resume-tracking', this.onResumeTracking);
        eventManager.listenTo('pause-tracking', this.onPauseTracking);

        // TODO: https://safetyculture.atlassian.net/browse/TRAINING-525
        eventManager.listenTo('comment-loaded', this.onCommentLoaded);
        eventManager.listenTo('error-sl', this.onErrorSL);
        eventManager.listenTo('nomore-comments', this.onNoMoreComments);

        // Set the window handler on the authoring bridge, to allow communication to the lesson window
        this.peerAuthoringBridge.activate(this.iFrameBridge, context.slidedeck.id);

        // startup events
        eventManager.listenToOnce(`${prefix}:thomas-ready`, () => {
          HostedWebviewUtils.triggerEvent('lessonLoaded');
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });

    /**
     * This promise ensures that opening thomas won't take longer than 120sec
     */
    const timeoutPromise = new Promise<void>((_, reject) => {
      setTimeout(() => reject('timeout to open thomas iframe'), 120 * 1000);
    });

    return Promise.race([openPromise, timeoutPromise]);
  };
}
